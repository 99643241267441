import FirstPageContent from "./FirstPageContent";
import Header from "./Header";
import useIsMobile from "../hooks/useIsMobile";
import bgMin from "../images/bgMin.svg";
import { openPicoDoc, openZkDoc } from "./Footer";

import { openTalk } from "./FirstPageContent";

function FirstPage() {
  const { isMobile } = useIsMobile();

  const list = [
    {
      text: "IT’S TRUSTLESS. IT’S BREVIS.",
      colorType: "green",
    },
    {
      text: "IT’S TRUSTLESS. IT’S BREVIS.",
      colorType: "rgba(164, 137, 243, 1)",
    },
    {
      text: "IT’S TRUSTLESS. IT’S BREVIS.",
      colorType: "rgba(243, 194, 8, 1)",
    },
    {
      text: "IT’S TRUSTLESS. IT’S BREVIS.",
      colorType: "rgba(249, 166, 225, 1)",
    },
    {
      text: "IT’S TRUSTLESS. IT’S BREVIS.",
      colorType: "rgba(55, 54, 220, 1)",
    },
  ];
  let newList = [];
  for (let i = 0; i < 50; i++) {
    newList = newList.concat(list);
  }
  return (
    <div className="firstPage" id="firstPage">
      <Header />
      <div className="firstPage_bg">
        {!isMobile && (
          <div>
            <div className="bodyImgMax1440">
              <div className="bgMaxLeft">
                {/* <img src={bgMaxLeft} alt="" /> */}
              </div>
              <div className="bgMaxRight">
                {/* <img src={bgMaxRight} alt="" /> */}
              </div>
            </div>
            <div className="bodyImgMin1440">
              <div className="bgMinLeft">
                {/* <img src={bgMinLeft} alt="" /> */}
              </div>
              <div className="bgMinRight">
                {/* <img src={bgMinRight} alt="" /> */}
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div>
            <img src={bgMin} className="bodyImg" alt="" />
            <div className="mobileFP_textArea">
              <div className="mobileFP_text1">
                <div>An Efficient,Verifiable</div>
                <div>Off-Chain Computation Engine</div>
                <div>Powered by</div>
                <div>Zero-Knowledge Proofs</div>
              </div>
              <div className="mobileFP_text2">
                Enable Web3 applications to offload data-intensive, costly
                computations from on-chain environments to a drastically
                lower-cost off-chain engine, and scale seamlessly while
                preserving the security of L1 trust assumptions.
              </div>
              <div className="buttons" key="buttons">
                <div
                  className="demoBtn"
                  onClick={() => {
                    openPicoDoc();
                  }}
                  style={isMobile ? { color: "rgba(255, 252, 228, 1)" } : {}}
                >
                  Pico docs
                </div>
                <div
                  className="demoBtn"
                  onClick={() => {
                    openZkDoc();
                  }}
                  style={isMobile ? { color: "rgba(255, 252, 228, 1)" } : {}}
                >
                  zkCoprocessor docs
                </div>
                <div
                  className="demoBtn"
                  onClick={() => {
                    openTalk();
                  }}
                  style={isMobile ? { color: "rgba(255, 252, 228, 1)" } : {}}
                >
                  Talk to a builder
                </div>
                {/* <div
                  className="demoBtnVideo"
                  onClick={() => {
                    showVideoModal();
                  }}
                >
                  <img src={playIcon} className="palyVideo" alt="paly video" />
                </div> */}
              </div>
            </div>
          </div>
        )}
        {!isMobile && <FirstPageContent />}
      </div>

      <div className="fp_bottom" id="fp_bottom">
        <div className="light">
          {newList.map((item, index) => {
            return (
              <div
                key={index}
                className="lightItem"
                style={{ color: item.colorType }}
              >
                {item.text}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FirstPage;
