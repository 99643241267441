import ScrollAnim from "rc-scroll-anim";
// import QueueAnim from "rc-queue-anim";
import Step from "./Step";
import useIsMobile from "../../hooks/useIsMobile";
import AnimatedContent from "../animatedContent/AnimatedContent";

const PicoPage = () => {
  const { isMobile } = useIsMobile();
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack;
  // const MyQueueAnim = isMobile ? "div" : QueueAnim;

  return (
    <div id="picoPage">
      <ScrollOverPack key={"step88"} id={"step88"} playScale={0.2}>
        <AnimatedContent>
          {!isMobile ? (
            <div className="zkTitle" key="MODULAR">
              A MODULAR, PERFORMANT ZKVM
            </div>
          ) : (
            <div className="zkTitle" key="MODULAR">
              <div>A MODULAR,</div> <div>PERFORMANT</div> ZKVM
            </div>
          )}
        </AnimatedContent>
      </ScrollOverPack>
      <Step />
    </div>
  );
};
export default PicoPage;
