import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { throttle } from "lodash";
import ZkCoprocessorPage from "./zkCoprocessorPage/ZkCoprocessorPage";
import PicoPage from "./pico/PicoPage";
import useIsMobile from "../hooks/useIsMobile";
import {
  setPageStatus,
  setPageToTopHeight,
  setBtnWidth,
  setBtnHeight,
  setIsFixedPosition,
  setBtnInitWidth,
  setBtnInitHeight,
  setIsInWindow,
  setMenuButtonDomTop,
  setIsHidden,
  setBtnAction,
  setBtnReady,
} from "../redux/dataSlice";
import { wheelUse } from "../App";
export function preventScroll(event) {
  event.preventDefault();
}

let timer;
let timer2;

const ContentPage = () => {
  const elementRef = useRef(null);

  const dispatch = useDispatch();
  const {
    pageStatus,
    pageToTopHeight,
    btnHeight,
    isFixedPosition,
    isInWindow,
    btnInitHeight,
    isHidden,
    btnAction,
    isMouseUp,
    btnWidth,
  } = useSelector((state) => state.data);
  const [isFixedBtn, setIsFixedBtn] = useState(false);
  const [mouseInPico, setMouseInPico] = useState(false);
  const [mouseInZk, setMouseInZK] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const { isMobile } = useIsMobile();
  const val = isMobile ? 60 : 128;
  const setHeightToTop = () => {
    setTimeout(() => {
      let root = document.body;
      let height = 0;
      let ele = document.getElementById("fp_bottom");
      do {
        height += ele.offsetTop;
        ele = ele.offsetParent;
      } while (ele !== root);
      const toTop = isMobile ? height + 130 : height - 30;

      dispatch(setPageToTopHeight(toTop - 80));
    }, 1000);
  };

  const setBtnPosition = () => {
    clearTimeout(timer);
    localStorage.setItem("newHeight", 68);
    localStorage.setItem("newWidth", 800);
    localStorage.setItem("textFontSize", 32);
    const box = document.getElementById("mouseTag");
    // eslint-disable-next-line no-undef
    gsap.to(box, {
      height: 68,
      width: 800,
      fontSize: 32,
      duration: 0.3,
      ease: "power3.out",
    });

    timer = setTimeout(() => {
      dispatch(setBtnWidth(800));
      dispatch(setBtnHeight(68));
      const element = document.querySelector(".menuBtn");
      element?.style.setProperty("border", "1px solid rgba(255, 252, 228, 1)");
      const element2 = document.querySelector(".menuOutItem");
      element2?.style.setProperty("border-radius", "10px");
      const element3 = document.querySelector(".menuOutItem2");
      element3?.style.setProperty("border-radius", "10px");
      dispatch(setIsFixedPosition(true));
      dispatch(setBtnReady(true));
      dispatch(setBtnAction(true));
      wheelUse();
    }, 300);
    clearTimeout(timer2);
    timer2 = setTimeout(() => {
      window.scrollTo({
        top: pageToTopHeight,
        behavior: "smooth",
      });
    }, 500);
  };

  function getDomToViewPosition(e) {
    if (isMobile) {
      const startDom = document.getElementById("fp_bottom");
      const endDom = document.getElementById("buttonLine");
      const rectObject = startDom.getBoundingClientRect();
      const rectObjectEnd = endDom.getBoundingClientRect();
      if (rectObject.top <= -50) {
        setIsFixedBtn(true);
        if (rectObjectEnd.top < 150) {
          setIsFixedBtn(false);
        }
      } else {
        setIsFixedBtn(false);
      }

      return;
    }
    const startDom = document.getElementById("menuButton");
    const endDom = document.getElementById("buttonLine");
    const rectObject = startDom.getBoundingClientRect();
    const rectObjectEnd = endDom.getBoundingClientRect();
    dispatch(setMenuButtonDomTop(rectObject.top));
    const dom = document.getElementById("mouseTag");
    const heightNow = dom.offsetHeight || dom.height || dom.clientHeight;
    const initHeight = window.innerHeight - 128;
    if (rectObject.top <= val) {
      dispatch(setIsFixedPosition(true));
      if (rectObjectEnd.top < 250) {
        dispatch(setIsHidden(true));
        dispatch(setBtnAction(false));
      } else {
        dispatch(setIsHidden(false));
      }
    } else {
      if (heightNow !== initHeight) {
        dispatch(setIsFixedPosition(true));
      } else {
        dispatch(setIsFixedPosition(false));
      }
    }
  }
  function addHandler(element, type, handler) {
    if (element.addEventListener) {
      element.addEventListener(type, handler, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + type, handler);
    } else {
      element["on" + type] = handler;
    }
  }

  useEffect(() => {
    addHandler(window, "scroll", getDomToViewPosition);
    setHeightToTop();
    if (isMobile) {
      return;
    }
    const intersectionObserver = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          dispatch(setIsInWindow(true));
        } else {
          dispatch(setIsInWindow(false));
        }
      }
    });

    const targetElement = document.querySelector(".menuPosition");
    intersectionObserver.observe(targetElement);
    const intersectionObserver2 = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setShowAction(true);
        } else {
          setShowAction(false);
        }
      }
    });

    const targetElement2 = document.querySelector(".buttonText");
    intersectionObserver2.observe(targetElement2);

    var startDom = document.getElementById("menuButton");
    let rectObject = startDom.getBoundingClientRect();
    dispatch(setMenuButtonDomTop(rectObject.top));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    throttle(() => {
      dispatch(setBtnInitWidth(window.innerWidth));
      dispatch(setBtnInitHeight(window.innerHeight - 128));
    }, 200),
    []
  );
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const getClassName = () => {
    let names = "menuBtnState";
    if (btnAction) {
      names = "menuBtnState btnReadyAction";
    }
    if (isHidden && !isMobile) {
      names = names + " menuBtnStateAction";
    }
    return names;
  };

  useEffect(() => {
    dispatch(setBtnInitWidth(window.innerWidth));
    dispatch(setBtnInitHeight(window.innerHeight - 128));
    dispatch(setBtnWidth(window.innerWidth));
    dispatch(setBtnHeight(window.innerHeight - 128));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMenuClassName = () => {
    let names = "menuOut";
    if (!isMouseUp) {
      if (isFixedPosition) {
        names = "menuOut menuOutFixed";
      }
    } else {
      if (isFixedPosition) {
        if (btnHeight !== btnInitHeight) {
          names = "menuOut menuOutFixed";
        } else {
          if (!isInWindow) {
            names = "menuOut menuOutFixed";
          }
        }
      }
    }

    // if (isMouseUp && isFixedPosition) {
    //   names = "menuOut menuOutFixed";
    // }
    if (btnAction) {
      names = names + " btnReadyAction";
    }
    return names;
  };
  const getMenuOutItemClass = () => {
    let nan = "menuOutItem";
    if (pageStatus === "pico" || mouseInPico) {
      nan = "menuOutItem menuOutActive animate__animated animate__slideInRight";
    }
    if (mouseInZk) {
      nan = "menuOutItem ";
    }
    return nan;
  };
  const getMenuOutItemRightClass = () => {
    let nan = "menuOutItem menuOutItem2";
    if (pageStatus === "zk" || mouseInZk) {
      nan =
        "menuOutItem menuOutItem2 menuOutActive animate__animated animate__slideInLeft";
    }
    if (mouseInPico) {
      nan = "menuOutItem menuOutItem2 ";
    }
    return nan;
  };

  const getTextColor = () => {
    let nan = "btnLeftText";
    if (pageStatus === "pico" || mouseInPico) {
      nan = "btnLeftText textActive";
    }
    if (mouseInZk) {
      nan = "btnLeftText ";
    }
    return nan;
  };
  const getRightTextColor = () => {
    let nan = "btnRightText";
    if (pageStatus === "zk" || mouseInZk) {
      nan = "btnRightText textActive";
    }
    if (mouseInPico) {
      nan = "btnRightText ";
    }
    return nan;
  };
  return (
    <div key="sad">
      <div
        className="menuPosition"
        id="menuPosition"
        style={{
          width: "100%",
          height: 1,
          marginBottom: 0,
        }}
      ></div>

      {!isMobile ? (
        <div
          className={getClassName()}
          style={{
            height: btnHeight === 68 ? btnHeight + 20 : btnInitHeight + 20,
          }}
          ref={elementRef}
        >
          <div
            className={getMenuClassName()}
            id="menuButton"
            style={{
              pointerEvents: isHidden ? "none" : "unset",
              height: btnHeight === 68 ? btnHeight : btnInitHeight,
            }}
          >
            <div
              className={"menuBtn"}
              // style={
              //   isMobile
              //     ? { width: "100%", height: "auto" }
              //     : { width: btnWidth, height: btnHeight }
              // }
              id="mouseTag"
              key="menuButton"
            >
              <div className={getMenuOutItemClass()}></div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setPageStatus("pico"));
                  if (btnWidth === 800) {
                    window.scrollTo({
                      top: pageToTopHeight,
                      behavior: "smooth",
                    });
                  } else {
                    setBtnPosition();
                  }
                }}
                id="btnLeftText"
                className={getTextColor()}
                onMouseEnter={(e) => {
                  if (btnHeight > 68) {
                    return;
                  }
                  e.stopPropagation();
                  setMouseInPico(true);
                }}
                onMouseLeave={(e) => {
                  e.stopPropagation();
                  setMouseInPico(false);
                }}
              >
                <div
                  className={
                    showAction
                      ? "buttonText tracking-in-contract-bck"
                      : "buttonText"
                  }
                >
                  Pico zkVM
                </div>
              </div>
              <div className={getMenuOutItemRightClass()}></div>
              <div
                className={getRightTextColor()}
                id="btnRightText"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setPageStatus("zk"));
                  if (btnWidth === 800) {
                    window.scrollTo({
                      top: pageToTopHeight,
                      behavior: "smooth",
                    });
                  } else {
                    setBtnPosition();
                  }
                }}
                onMouseEnter={(e) => {
                  if (btnHeight > 68) {
                    return;
                  }
                  e.stopPropagation();
                  setMouseInZK(true);
                }}
                onMouseLeave={(e) => {
                  e.stopPropagation();
                  setMouseInZK(false);
                }}
              >
                <div
                  className={
                    showAction
                      ? "buttonText tracking-in-contract-bck"
                      : "buttonText"
                  }
                >
                  zkCoprocessor
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: 92 }}>
          <div
            id="mobileBtn"
            className={isFixedBtn ? "mobileBtn mobileBtnFixed" : "mobileBtn"}
          >
            <div className="mobileBtnBody">
              <div
                className={
                  pageStatus === "pico"
                    ? "mobileBtnItem mobileBtnItemActive"
                    : "mobileBtnItem"
                }
                onClick={() => {
                  dispatch(setPageStatus("pico"));
                  window.scrollTo({
                    top: pageToTopHeight,
                    behavior: "smooth",
                  });
                }}
              >
                Pico zkVM
              </div>
              <div
                className={
                  pageStatus === "zk"
                    ? "mobileBtnItem mobileBtnItemActive"
                    : "mobileBtnItem"
                }
                onClick={() => {
                  dispatch(setPageStatus("zk"));
                  window.scrollTo({
                    top: pageToTopHeight,
                    behavior: "smooth",
                  });
                }}
              >
                zkCoprocessor
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="pageView" id="pageView" style={{ marginTop: 60 }}>
        <div>
          {pageStatus === "pico" ? <PicoPage /> : <ZkCoprocessorPage />}
        </div>
        <div className="buttonLine" id="buttonLine"></div>
      </div>
    </div>
  );
};
export default ContentPage;
