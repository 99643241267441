import "animate.css";
import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { throttle } from "lodash";
import "./App.css";
import FirstPage from "./pages/FirstPage";

import Empower from "./pages/Empower";
import HelpYou from "./pages/HelpYou";
import Footer from "./pages/Footer";
import Teams from "./pages/Teams";
import ContentPage from "./pages/ContentPage";
import useIsMobile from "./hooks/useIsMobile";
import {
  setBtnWidth,
  setBtnHeight,
  setIsFixedPosition,
  heightStep,
  setBtnReady,
  setBtnAction,
  setIsMouseUp,
} from "./redux/dataSlice";

let timer;
export const wheelUse = () => {
  // document.removeEventListener("wheel", preventScroll);
  document.body.style.overflow = "auto";
  document.body.style.marginRight = "0";
  const element = document.querySelector(".header");
  element.style.setProperty("width", "100%");
};
export const wheelDisUse = () => {
  // document.addEventListener("wheel", preventScroll, { passive: false });
  document.body.style.overflow = "hidden";
  document.body.style.marginRight = "6px";
  const element = document.querySelector(".header");
  element.style.setProperty("width", "calc(100% - 6px)");
};
function App() {
  const { isMobile } = useIsMobile();
  const val = isMobile ? 60 : 128;
  const dispatch = useDispatch();
  const {
    stepNum,
    btnInitWidth,
    btnInitHeight,
    isInWindow,
    menuButtonDomTop,
    pageToTopHeight,
    isHidden,
    btnAction,
    textFontSize,
  } = useSelector((state) => state.data);

  const setBtnToBig = () => {
    const element = document.querySelector(".menuBtn");
    element.style.setProperty("border", "none");
    const element2 = document.querySelector(".menuOutItem");
    element2?.style.setProperty("border-radius", "0");
    const element3 = document.querySelector(".menuOutItem2");
    element3?.style.setProperty("border-radius", "0");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    throttle((e) => {
      wheelChange(e);
    }, 100),
    [menuButtonDomTop, isHidden, isInWindow, btnAction, heightStep, stepNum]
  );
  useEffect(() => {
    localStorage.setItem("btnInitHeight", btnInitHeight);
    localStorage.setItem("btnInitWidth", btnInitWidth);
    localStorage.setItem("textFontSize", textFontSize);
  }, [btnInitHeight, btnInitWidth, textFontSize]);

  useEffect(() => {
    localStorage.setItem("newHeight", 0);
    localStorage.setItem("newWidth", 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPageScroll = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      window.scrollTo({
        top: pageToTopHeight,
        behavior: "smooth",
      });
      wheelUse();
    }, 200);
  };
  const wheelChange = (e) => {
    if (isMobile) {
      return;
    }
    let newHeight = Number(localStorage.getItem("newHeight") || 0);
    let newWidth = Number(localStorage.getItem("newWidth") || 0);
    let btnInitHeight = Number(localStorage.getItem("btnInitHeight"));
    let btnInitWidth = Number(localStorage.getItem("btnInitWidth"));
    let newFontSize = Number(localStorage.getItem("textFontSize"));
    if (
      !newHeight ||
      newHeight === "" ||
      isNaN(newHeight) ||
      !newWidth ||
      newWidth === "" ||
      isNaN(newWidth)
    ) {
      newHeight = btnInitHeight;
      newWidth = btnInitWidth;
    }
    var topDom = document.getElementById("firstPage");
    let rectObject2 = topDom.getBoundingClientRect();

    if (rectObject2.top > 0) {
      newHeight = btnInitHeight;
      newWidth = btnInitWidth;
      newFontSize = textFontSize;
      // dispatch(setBtnWidth(btnInitWidth));
      // dispatch(setBtnHeight(btnInitHeight));
      dispatch(setIsFixedPosition(false));
      wheelUse();
      setBtnToBig();
      return;
    }
    let scrollDistance = e.deltaY;

    if (scrollDistance > 0) {
      // go down
      dispatch(setIsMouseUp(false));
      if (
        (newHeight === 68 || newHeight === btnInitHeight) &&
        menuButtonDomTop > val
      ) {
        return;
      }
      let percentage =
        newHeight - 68 === 0 ? 0 : scrollDistance / (newHeight - 68);
      // if (percentage > 0.5) {
      //   scrollDistance = (newHeight - 68) * 0.5;
      //   percentage = 0.5;
      // }
      if (scrollDistance < 20) {
        scrollDistance = 80;
      }
      const widthDistance = (newWidth - 800) * percentage;
      const num =
        (newFontSize - 32) * percentage < 0
          ? 1
          : (newFontSize - 32) * percentage;
      let fontSizeNum = newFontSize - Number(num.toFixed(0));
      if (newWidth - widthDistance > 800) {
        dispatch(setBtnReady(false));
        newWidth = newWidth - widthDistance;
      }
      if (newHeight - scrollDistance > 68) {
        wheelDisUse();
        newHeight = newHeight - scrollDistance;
        newFontSize = fontSizeNum <= 32 ? 32 : fontSizeNum;
      }
      if (newHeight - scrollDistance <= 68 || newWidth - widthDistance <= 800) {
        if (!isHidden && (newHeight !== 68 || newWidth !== 800)) {
          dispatch(setBtnReady(true));
          dispatch(setBtnAction(true));
        }
        if (newHeight !== 68 || newWidth !== 800) {
          setPageScroll();
        }
        const element = document.querySelector(".menuBtn");
        element?.style.setProperty(
          "border",
          "1px solid rgba(255, 252, 228, 1)"
        );
        element?.style.setProperty("font-size", "32px");
        const element2 = document.querySelector(".menuOutItem");
        element2?.style.setProperty("border-radius", "10px");
        const element3 = document.querySelector(".menuOutItem2");
        element3?.style.setProperty("border-radius", "10px");

        newHeight = 68;
        newWidth = 800;
        newFontSize = 32;
      }
    } else {
      dispatch(setIsMouseUp(true));
      clearTimeout(timer);
      if (!isInWindow) {
        if (newHeight === 68) {
          wheelUse();
          return;
        }
      }
      if (btnAction) {
        dispatch(setBtnAction(false));
      }
      let percentage =
        btnInitHeight - newHeight === 0
          ? 0
          : scrollDistance / (btnInitHeight - newHeight);
      // if (percentage < -0.5) {
      //   percentage = -0.5;
      //   scrollDistance = (btnInitHeight - newHeight) * 0.5;
      // }
      if (scrollDistance < -50) {
        scrollDistance = -100;
      }
      const widthDistance = (btnInitWidth - newWidth) * percentage;
      const num =
        (textFontSize - newFontSize) * percentage > -1
          ? -1
          : (textFontSize - newFontSize) * percentage;
      const fontSizeNum = newFontSize - Number(num.toFixed(0));
      if (newWidth - widthDistance < btnInitWidth) {
        newWidth = newWidth - widthDistance;
      }
      if (newHeight - scrollDistance < btnInitHeight) {
        dispatch(setBtnReady(false));
        dispatch(setBtnAction(false));
        const element = document.querySelector(".menuBtn");
        element.style.setProperty("border", "none");
        const element2 = document.querySelector(".menuOutItem");
        element2?.style.setProperty("border-radius", "0");
        const element3 = document.querySelector(".menuOutItem2");
        element3?.style.setProperty("border-radius", "0");
        newFontSize = fontSizeNum >= 52 ? 52 : fontSizeNum;
        wheelDisUse();
        newHeight = newHeight - scrollDistance;
      }
      if (
        newHeight - scrollDistance >= btnInitHeight ||
        newWidth - widthDistance >= btnInitWidth
      ) {
        newWidth = btnInitWidth;
        newHeight = btnInitHeight;
        newFontSize = textFontSize;
        dispatch(setIsFixedPosition(false));
        dispatch(setBtnReady(false));
        dispatch(setBtnAction(false));
        wheelUse();
        setBtnToBig();
      }
    }

    dispatch(setBtnWidth(newWidth));
    dispatch(setBtnHeight(newHeight));
    localStorage.setItem("newHeight", newHeight);
    localStorage.setItem("newWidth", newWidth);
    localStorage.setItem("textFontSize", newFontSize);
    const box = document.getElementById("mouseTag");
    // eslint-disable-next-line no-undef
    gsap.to(box, {
      height: newHeight,
      width: newWidth,
      fontSize: newFontSize,
      duration: 0.3,
      ease: "power3.out",
    });
  };

  return (
    <div className="App" id="app" onWheel={(e) => handleScroll(e)}>
      <FirstPage />
      <ContentPage />
      <Empower />
      <Teams />
      <HelpYou />
      <Footer />
    </div>
  );
}

export default App;
