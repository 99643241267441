import { createSlice } from "@reduxjs/toolkit";
export const heightStep = 60;
export const counterSlice = createSlice({
  name: "data",
  initialState: {
    showModal: false,
    pageStatus: "pico",
    pageToTopHeight: undefined,
    btnWidth: "100%",
    btnHeight: "calc(100vh - 128px)",
    isFixedPosition: false,
    btnInitWidth: 0,
    btnInitHeight: "",
    stepNum: heightStep,
    isInWindow: false,
    isHidden: false,
    menuButtonDomTop: 0,
    btnReady: false,
    btnAction: false,
    textFontSize: 60,
    isMouseUp: false,
  },
  reducers: {
    setShowModal: (state, { payload }) => {
      state.showModal = payload;
    },
    setPageStatus: (state, { payload }) => {
      state.pageStatus = payload;
    },
    setPageToTopHeight: (state, { payload }) => {
      state.pageToTopHeight = payload;
    },
    setBtnWidth: (state, { payload }) => {
      state.btnWidth = payload;
    },
    setBtnHeight: (state, { payload }) => {
      state.btnHeight = payload;
    },
    setIsFixedPosition: (state, { payload }) => {
      state.isFixedPosition = payload;
    },
    setBtnInitWidth: (state, { payload }) => {
      state.btnInitWidth = payload;
    },
    setBtnInitHeight: (state, { payload }) => {
      state.btnInitHeight = payload;
    },
    setStepNum: (state, { payload }) => {
      state.stepNum = payload;
    },
    setIsInWindow: (state, { payload }) => {
      state.isInWindow = payload;
    },
    setMenuButtonDomTop: (state, { payload }) => {
      state.menuButtonDomTop = payload;
    },
    setIsHidden: (state, { payload }) => {
      state.isHidden = payload;
    },
    setBtnReady: (state, { payload }) => {
      state.btnReady = payload;
    },
    setBtnAction: (state, { payload }) => {
      state.btnAction = payload;
    },
    setTextFontSize: (state, { payload }) => {
      state.textFontSize = payload;
    },
    setIsMouseUp: (state, { payload }) => {
      state.isMouseUp = payload;
    },
  },
});

export const {
  setShowModal,
  setPageStatus,
  setPageToTopHeight,
  setBtnWidth,
  setBtnHeight,
  setIsFixedPosition,
  setBtnInitWidth,
  setBtnInitHeight,
  setStepNum,
  setIsInWindow,
  setMenuButtonDomTop,
  setIsHidden,
  setBtnReady,
  setBtnAction,
  setTextFontSize,
  setIsMouseUp,
} = counterSlice.actions;

export default counterSlice.reducer;
